@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500&display=swap');
.navbar {
    display: flex;
    gap: 2rem;
    align-items: center;
    justify-content: space-between;
    background-color: #1E2D3B;
    position: relative;
    z-index: 899;
}

nav{
    padding-right: 3rem;
}

nav>ul {
    display: flex;
    justify-content: center;
    align-items: center;
}

.nav-lang-img {
    width: 1vmax;
}

.logo{
    margin: 0.3rem;
}

.logo_img {
    width: 3.8vmax;
}

.not-mobile {
    display: flex;
    gap: 0.8vmax;
}

.mobile-toggle {
    display: none;
}

.nav-item {
    font-family: 'Roboto', sans-serif;
    display: inline-block;
    text-decoration: none;
    font-size: 1vmax;
    position: relative;
    padding: 0.7vmax 1.3vmax;
    color: #E0E0E0;
}

.nav-button {
    background-color: #FFCF0E;
    font-weight: bold;
    color: #202124;
    border-radius: 62px;
}

.nav-item-hover:hover {
    background-color: #FFCF0E;
    font-weight: bold;
    color: #202124;
    padding: 1.5vmax 1.3vmax;
}

@media(max-width: 50em) {
    .mobile {
        display: flex;
        gap: 2rem;
        position: fixed;
        inset: 0 0 0 40%;
        background: #E0E0E0;
        flex-direction: column;
    }
    .not-mobile {
        display: none;
    }
    .mobile-toggle {
        display: block;
        position: absolute;
        color: #FFCF0E;
        width: 3vmax;
        aspect-ratio: 1;
        top: 0.8vmax;
        right: 5vmax;
        z-index: 9999;
        border: 0;
        font-size: 3vmax;
        background-color: transparent;
        transition: all 0.5s ease;
    }
    nav>ul{
        display: flex;
        justify-content: flex-start;
        padding-top: 7.5vmax;
    }
    .nav-item {
        color: #1E2D3B;
        font-size: 2vmax;
    }
    .nav-item-hover:hover{
        width: 29em;
        text-align: center;
    }
}