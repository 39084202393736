@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Rasa:wght@600&display=swap');

.main_hero {
    display: flex;
    justify-content: space-evenly;
    padding-top: 1vmax;
    padding-bottom: 1vmax;
    position: relative;
    box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.1);
}

.hero_img {
    width: 10vmax;
    height: 10vmax;
    object-fit: cover;
    border-radius: 50%;
}

.hero_active {
    width: 0.65vmax;
    height: 0.65vmax;
    background: #00E171;
    border: 1px solid #FFFFFF;
    box-sizing: border-box;
    border-radius: 50%;
    z-index: 10;
    position: relative;
    left: 8vmax;
    bottom: 1.55vmax;
}

.hero_name {
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 1.7vmax;
    line-height: 2.4vmax;
    padding-bottom: 0.5vmax;
    color: #202124;
}

.hero_des {
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 1.2vmax;
    line-height: 2vmax;
    color: #343B40;
}

.hero_subname {
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 0.9vmax;
    line-height: 2vmax;
    color: #979DA0;
}

.hero_status {
    display: flex;
    margin-left: -1vmax;
    align-items: center;
}

.hero_status>p {
    background: rgba(255, 241, 208, 0.56);
    border: 0.5px solid #FFCF0E;
    box-sizing: border-box;
    border-radius: 3px;
    font-family: 'Rasa', serif;
    font-style: normal;
    font-weight: 600;
    font-size: 0.8vmax;
    height: 1.2vmax;
    padding-right: 0.5vmax;
    color: #FEB72C;
    display: flex;
    align-items: center;
}

.verify {
    padding-left: 1.4vmax;
}

.visits {
    padding-left: 2.1vmax;
}

.aadhaar {
    width: 4%;
    z-index: 10;
    position: relative;
    left: 1vmax;
}

.eye {
    width: 3.5%;
    z-index: 10;
    position: relative;
    left: 1.4vmax;
}

.hero_tabs {
    padding-top: 5vmax;
    display: flex;
    gap: 2vmax;
}

.hero_tabs>p {
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 1.1vmax;
    letter-spacing: 0.02em;
    color: #202124;
    cursor: pointer;
}

.active_status {
    background-color: #F8F8F8;
    text-align: center;
    display: flex;
    justify-content: center;
    padding-top: 2vmax;
    padding-bottom: 2vmax;
}

.active_card {
    width: 56.9vmax;
    padding: 0.5vmax 2vmax 3vmax 2vmax;
    background-color: #FFF;
}

.active_card_hero {
    display: flex;
    justify-content: space-between;
}

.active_card_hero_left {
    display: flex;
    align-items: center;
}

.active_card_hero_right {
    display: flex;
    gap: 0.7vmax;
}

.arrow {
    border: 1px solid #ECEDEE;
    box-sizing: border-box;
    width: 2.5vmax;
    height: 2.5vmax;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.arrow_prev {
    transform: rotate(180deg);
    width: 0.54vmax;
}

.arrow_next {
    width: 0.54vmax;
}

.active_loads {
    font-family: Montserrat;
    font-style: normal;
    font-weight: bold;
    font-size: 1.3vmax;
    color: #000000;
}

.active_live {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 1vmax;
    color: #F52926;
    border: 1px solid #F52926;
    box-sizing: border-box;
    border-radius: 5px;
    padding-left: 1.2vmax;
    padding-right: 0.4vmax;
}

.active_des {
    text-align: left;
    margin-top: 1vmax;
    padding: 1vmax;
    border: 1px solid #E5E6E7;
    box-sizing: border-box;
}

.active_des_name {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    font-size: 0.95vmax;
    line-height: 1.5vmax;
    color: #010A10;
}

.active_des_subname {
    font-family: Montserrat;
    font-style: normal;
    font-weight: normal;
    font-size: 0.7vmax;
    color: #979DA0;
    padding-bottom: 1vmax;
    border-bottom: 1px dashed #CED3D7;
}

.active_des_weight {
    padding-top: 1vmax;
    display: flex;
    align-items: center;
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 0.9vmax;
    line-height: 19px;
    color: #3D5062;
}

.active_des_bill {
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 0.9vmax;
    line-height: 19px;
    color: #3D5062;
    padding-top: 0.4vmax;
    padding-bottom: 0.8vmax;
}

.truck_img {
    width: 1.15vmax;
}

.card_img {
    width: 1vmax;
}

.active_des_remark {
    text-decoration: none;
    font-family: Montserrat;
    font-style: normal;
    font-weight: normal;
    font-size: 0.75vmax;
    line-height: 17px;
    color: #A8B1B9;
    display: flex;
    align-items: center;
    margin-bottom: 1.3vmax;
}

.ibutton_img {
    width: 1.5%;
}

.bid {
    background: #FBFFCF;
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 0.95vmax;
    line-height: 2.8vmax;
    text-align: center;
    color: #3D3919;
    cursor: pointer;
    margin-bottom: 0.5vmax;
}

.bid:hover {
    background: #FFCF0E;
    font-weight: 600;
}