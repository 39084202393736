.driver_hero {
    background: #FFCF0E;
    text-align: center;
    padding-top: 2vmax;
    padding-bottom: 6vmax;
}

.driver_hero_title {
    font-family: Roboto;
    font-style: normal;
    font-weight: bold;
    font-size: 2.8vmax;
    line-height: 3.2vmax;
    color: #202124;
}

.driver_hero_subtitle {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 2.2vmax;
    line-height: 3vmax;
    color: #202124;
}

.search_engine {
    background: #FFFFFF;
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.05);
    margin-left: 3vmax;
    margin-right: 3vmax;
    position: relative;
    z-index: 10;
    top: -5vmax;
    padding: 1vmax 3vmax;
}

.driver_search {
    text-align: left;
    display: flex;
    align-items: center;
    gap: 3vmax;
    padding-bottom: 1vmax;
}

.driver_search_title {
    font-family: Roboto;
    font-style: normal;
    font-weight: 800;
    font-size: 1.3vmax;
    line-height: 29px;
    letter-spacing: 0.01em;
    color: #202124;
}

.radio_button {
    display: flex;
    align-items: center;
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 1.75vmax;
    letter-spacing: 0.003em;
    color: #202124;
}

.place_search {
    display: flex;
}

.city_search {
    flex: 1;
    text-align: left;
    border-right: 1px solid #E6E7E8;
}

.city_search>p, .state_search>p {
    font-family: Roboto;
    font-style: normal;
    font-weight: 800;
    font-size: 1.2vmax;
    line-height: 2vmax;
    letter-spacing: 0.01em;
    color: #202124;
}

.state_search {
    flex: 2.4;
    text-align: left;
    padding-left: 1.4vmax;
}

.select_state {
    display: flex;
    gap: 0.5vmax;
    padding-top: 0.5vmax;
}

.default_state {
    font-family: Roboto;
    font-style: normal;
    font-weight: 300;
    font-size: 1vmax;
    color: #000000;
    padding: 0.6vmax 1.3vmax;
    border: 1px solid #CCCFD1;
    box-sizing: border-box;
    border-radius: 22.5px;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 0.5vmax;
}

.default_state>img {
    width: 0.8vmax;
}

.select_state_search {
    display: flex;
    align-items: center;
    gap: 0.5vmax;
}

.search {
	position: absolute;
	font-family: Roboto;
    font-style: normal;
    font-weight: 600;
    font-size: 1vmax;
    color: #3D3919;
    padding: 0.6vmax 1.5vmax;
    border-radius: 22.5px;
    background: #FBFFCF;
    border: none;
    cursor: pointer;
	width: 8vmax;
	height: 2.55vmax;
	z-index: 100;
	transition-duration: 0.4s;
	-moz-transition-duration: 0.4s;
	-webkit-transition-duration: 0.4s;
	-o-transition-duration: 0.4s;
    background-image: url('../../assets/search.svg');
    background-repeat: no-repeat;
    background-position: 85%;
    background-size: 20%;
}

.search:focus {
	width: 41.7vmax;
    background-image: none;
    outline: none;
}

.expandright {
	left: auto;
	right: 22.5vmax;
}

.expandright:focus {
	padding: 0 0 0 1vmax;
}

.city_input {
    border: none;
    background: #f2f2f2;
    padding: 1vmax;
    border-radius: 3px;
    width: 22vmax;
    font-size: 1.2vmax;
}

.search_results {
    position: absolute;
    top: 9.5vmax;
    left: 30vmax;
    max-height: 12.6vmax;
    overflow: hidden;
    overflow-y: auto;
    width: 25vmax;
    background-color: #FBFFCF;
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 2vmax;
    color: #3D3919;
    z-index: 50;
}
.city_search_results {
    position: absolute;
    top: 9.8vmax;
    left: 3vmax;
    width: 22vmax;
}
.search_result_item {
    padding: 0.5vmax 1vmax;
}
.search_result_item:hover {
    background-color: #FFCF0E;
    font-weight: 600;
    cursor: pointer;
}

.driver_results_hero {
    display: flex;
    justify-content: space-between;
    margin-left: 4vmax;
    margin-right: 4vmax;
    margin-top: -2.5vmax;
    font-family: Roboto;
    font-style: normal;
    font-weight: 600;
    font-size: 3.2vmax;
    color: #000000;
}

.driver_results_right {
    display: flex;
    gap: 0.5vmax;
    align-items: center;
}

.driver_results_right>p {
    padding-right: 0.5vmax;
}

.driver_results_right>a {
    border: 1px solid #888B8D;
}

.driver_results {
    padding: 1vmax 4vmax;
    display: flex;
    flex-wrap: wrap;
    gap: 2vmax;
    align-items: center;
    justify-content: center;
}

.pagination {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1vmax;
    padding-top: 1vmax;
    margin-bottom: 3vmax;
}

@media(max-width: 1200px) {
    .search_results {
        top: 10.5vmax;
    }
}
@media(max-width: 900px) {
    .search_results {
        top: 11vmax;
    }
}
@media(max-width: 800px) {
    .place_search {
        flex-direction: column;
    }
    .state_search {
        padding-left: 0;
    }
    .expandright {
        right: calc(91vw - 41.7vmax);
    }
    .city_search_results {
        top: 11.5vmax;
    }
    .search_results {
        left: 3vmax;
        top: 16.8vmax;
    }
}
@media(max-width: 700px) {
    .city_search_results {
        top: 12vmax;
    }
    .search_results {
        top: 17.5vmax;
    }
}
@media(max-width: 650px) {
    .expandright {
        right: calc(90vw - 41.7vmax);
    }
    .city_search_results {
        top: 13vmax;
    }
    .search_results {
        top: 18vmax;
    }
}
@media(max-width: 555px) {
    .expandright {
        right: calc(88vw - 41.7vmax);
    }
    .driver_search {
        gap: 1vmax;
    }
    .city_search_results {
        top: 14vmax;
    }
    .search_results {
        top: 19vmax;
    }
}
@media(max-width: 475px) {
    .expandright {
        right: calc(86.5vw - 41.7vmax);
    }
    .city_search_results {
        top: 15vmax;
    }
}
@media(max-width: 420px) {
    #Karnataka {
        display: none;
    }
    .search:focus {
        width: 33vmax;
    }
    .expandright {
        right: calc(82vw - 33vmax);
    }
}
@media(max-width: 400px) {
    .expandright {
        right: calc(82vw - 33vmax);
    }
}