.driver_card {
    width: calc(88vmax/3);
    background: #FFFFFF;
    border: 1px solid #E5E6E7;
    box-sizing: border-box;
    padding: 1.5vmax;
}

.driver_card_location {
    font-family: Roboto;
    font-style: normal;
    font-weight: 600;
    font-size: 1.1vmax;
    line-height: 1.8vmax;
    letter-spacing: 0.01em;
    color: #8D9297;
}

.driver_card_name {
    font-family: Roboto;
    font-style: normal;
    font-weight: bold;
    font-size: 1.4vmax;
    line-height: 2vmax;
    color: #181D20;
}

.driver_card_role {
    font-family: Roboto;
    font-style: normal;
    font-weight: 300;
    font-size: 0.95vmax;
    line-height: 21px;
    letter-spacing: 0.01em;
    color: #4F5559;
    padding-bottom: 1.5vmax;
}

.driver_card_link {
    display: flex;
    gap: 1vmax;
}

.driver_card_link_profile {
    flex: 1;
    background: #FBFFCF;
    font-family: Roboto;
    font-style: normal;
    font-weight: 600;
    font-size: 0.9vmax;
    padding: 1vmax 0;
    text-align: center;
    color: #3D3919;
    cursor: not-allowed;
    display: flex;
    align-items: center;
    justify-content: center;
}

.driver_card_link_contact {
    flex: 2;
    border: 2px solid rgba(253, 203, 0, 0.44);
    box-sizing: border-box;
    font-family: Roboto;
    font-style: normal;
    font-weight: 600;
    font-size: 0.9vmax;
    padding: 1vmax 0;
    color: #3D3919;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
}

.driver_card_link_contact:hover {
    background: #FFCF0E;
}

.driver_card_hero {
    display: flex;
    gap: 2vmax;
}

.driver_card_img {
    padding: 0.5vmax;
}

.driver_card_img>img {
    width: 7vmax;
    height: 7vmax;
    object-fit: cover;
    border-radius: 50%;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}

.driver_card_active {
    width: 0.65vmax;
    height: 0.65vmax;
    background: #00E171;
    border: 1px solid #FFFFFF;
    box-sizing: border-box;
    border-radius: 50%;
    z-index: 10;
    position: relative;
    left: 5.9vmax;
    bottom: 1.55vmax;
}

.driver_card_stats {
    display: flex;
    flex-direction: column;
    gap: 1vmax;
}

.driver_card_stats>p {
    font-family: Roboto;
    font-style: normal;
    font-weight: 600;
    font-size: 0.9vmax;
    color: #535976;
    display: flex;
    align-items: center;
    gap: 0.4vmax;
    background: #F5F5F5;
    border-radius: 21px;
    padding: 0.4vmax 0.6vmax;
    width: fit-content;
}

.driver_card_stats>p>img {
    width: 1vmax;
}

.pagination>a {
    text-decoration: none;
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 1.1vmax;
    color: #8C91A4
}

.pagination>a:hover {
    background: #FFCF0E;
    color: #000000;
    font-weight: bold;
}

.modal-style {
    font-family: 'Roboto', sans-serif;
}
.modal_content {
    padding: 1vmax 1.5vmax;
}
.modal_hero {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.modal_hero>p {
    font-family: Roboto;
    font-style: normal;
    font-weight: bold;
    font-size: 1.8vmax;
    color: #181D20;
}
.modal_close {
    font-family: Montserrat;
    text-decoration: none;
    font-size: 1.5vmax;
    background: #F2F2F2;
    color: #646363;
}
.modal_title {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 0.8vmax;
    letter-spacing: 0.01em;
    color: #4F5559;
    margin-top: 2.5vmax;
}
.modal_submit {
    width: 27vmax;
    text-align: center;
    position: absolute;
    bottom: 2vmax;
    border-radius: 6px;
    font-size: 1vmax;
    border: none;
    cursor: pointer;
}
.modal_submit:hover {
    background: #FFCF0E;
}
.modal_input {
    margin-top: 1vmax;
    border: none;
    background: #f2f2f2;
    padding: 1vmax;
    border-radius: 3px;
    width: 24.8vmax;
    font-size: 1.2vmax;
}

.whatsapp_updates {
    width: 50%;
    padding-top: 1vmax;
}