.footer {
    padding: 1.5vmax 2vmax 8vmax 2vmax;
    background: #C3C3C3;
    display: flex;
    gap: 2vmax;
}

.footer_title {
    font-family: Montserrat;
    font-style: normal;
    font-weight: normal;
    font-size: 1.3vmax;
    color: #000000;
    padding-bottom: 2vmax;
}

.brand_img {
    width: 23vmax;
    display: flex;
    flex-direction: column;
    gap: 1vmax;
}

.play_img {
    width: 11vmax;
}

.brand_img>p {
    display: flex;
    align-items: center;
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 2.2vmax;
    color: #000000;
}

.company_details {
    width: 23vmax;
}

.footer_subtitle {
    padding-bottom: 1vmax;
}

.company_details_info {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 0.9vmax;
    line-height: 1.2vmax;
    color: #000000;
}

.social {
    width: 23vmax;
}

.social_links {
    display: flex;
    flex-direction: column;
    gap: 0.5vmax;
}

.social_links>a {
    text-decoration: none;
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 1.3vmax;
    color: #6C757D;
    display: flex;
    gap: 0.5vmax;
    align-items: center;
    width: fit-content;
}

.contact {
    width: 23vmax;
}

.head_office {
    font-family: Montserrat;
    font-style: normal;
    font-weight: normal;
    color: #212529;
    font-size: 1.1vmax;
    padding-bottom: 0.5vmax;
}

.address {
    font-family: Montserrat;
    font-style: normal;
    font-weight: normal;
    color: #212529;
    font-size: 0.8vmax;
    line-height: 1.2vmax;
    
}